// extracted by mini-css-extract-plugin
export var bottle_img = "product-module--bottle_img---+MUM";
export var content = "product-module--content--YnVZy";
export var details = "product-module--details--BvlHa";
export var flip_img = "product-module--flip_img--yt+ak";
export var img_section = "product-module--img_section--Qd0Yi";
export var menu_item = "product-module--menu_item--Q2yea";
export var menu_list = "product-module--menu_list--iq4GX";
export var selected = "product-module--selected--1mSd7";
export var taste_title = "product-module--taste_title--Q5AiT";
export var wrapper = "product-module--wrapper--BHfJi";
export var wrapper2 = "product-module--wrapper2--cYlf4";
export var wrapper_content = "product-module--wrapper_content--LOTr1";